import { closeModal } from "app/slices/modalSlice";

import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Input, Select } from "components/Shared";
import { useDispatch } from "react-redux";
import { RouterSupportedDOCountry } from "interfaces/enums";
import { useGetUserQuery } from "api/institutional/user";
import { ChangeEvent, useState } from "react";
import { USAccount } from "./UsAccountForm";
import { useGetRouterSubAccountsQuery } from "api/institutional/router_api";
import { MexicanAccount } from "./MexicanAccountForm";
import { findOption } from "utils";

export const AddBankAccountModalRouter = ({
  data,
}: {
  data: { country: RouterSupportedDOCountry };
}) => {
  const { t } = useTranslation();

  // APIs

  const handleClose = () => {
    dispatch(closeModal());
  };

  const dispatch = useDispatch();

  const { data: user } = useGetUserQuery({});

  const { data: subAccounts = [] } = useGetRouterSubAccountsQuery(undefined, {
    skip: !user?.isRouterBroker,
  });

  const {
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<{ account_name: string }>({
    defaultValues: {
      account_name: "",
    },
  });

  const accountName = watch("account_name");

  const [subAccountId, setSubAccountId] = useState("");
  const [country, setCountry] = useState(data.country);

  console.log({ country });

  const countryOptions = [
    { label: "US", value: RouterSupportedDOCountry.US },
    { label: "Mexico", value: RouterSupportedDOCountry.MX },
  ];

  return (
    <Modal>
      <Modal.Header handleClose={handleClose}>
        <span className="md:text-7 text-6 font-interSemiBold text-black">
          {t("Add New Destination Bank Account")}
        </span>
      </Modal.Header>
      <Modal.Body>
        {user?.isRouterBroker && (
          <div>
            <p className=" text-4 font-interSemiBold">{t("Client")}:</p>
            <Select
              isClearable
              options={subAccounts.map((s) => ({
                label: s.client_name,
                value: s.sub_account_id,
              }))}
              onChange={(e: any) => {
                setSubAccountId(e?.value);
              }}
              placeholder={t("Select an option")}
            />
          </div>
        )}

        {/* Recipient Name */}
        <div>
          <p className=" text-4 font-interSemiBold">{t("Recipient's Name")}:</p>
          <Input
            type="text"
            placeholder={t("Enter the full name of the recipient")}
            className="shadow-sm"
            bgColor="gray-light102"
            errors={errors}
            value={getValues("account_name")}
            onBlur={() =>
              setValue("account_name", getValues("account_name").trim())
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue("account_name", e.target.value)
            }
          />
        </div>

        {/* Transfer Type */}
        <div>
          <p className=" text-4 font-interSemiBold">
            {t("Destination Country")}:
          </p>
          <Select
            defaultValue={[findOption(country, countryOptions)]}
            options={countryOptions}
            onChange={(e: any) => {
              setCountry(e.value);
            }}
            placeholder={t("Select an option")}
          />
        </div>

        {country === RouterSupportedDOCountry.US ? (
          <USAccount
            handleClose={handleClose}
            subAccountId={subAccountId}
            country={country}
            accountName={accountName}
          />
        ) : (
          <MexicanAccount
            handleClose={handleClose}
            subAccountId={subAccountId}
            country={country}
            accountName={accountName}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddBankAccountModalRouter;
