import React from "react";
import { FlagEnum, Locations, Roles } from "interfaces/enums";
import { Article, AccountBalance, HowToReg } from "@mui/icons-material";
import {
  AdminDashboardIcon,
  DashboardIcon,
  ExchangesIcon,
  ExecutionIcon,
  OrdersIcon,
} from "assets/icons/MenuIcons";

import { ISidebarMenuOptions } from "interfaces";

export const MenuOptions = [
  {
    icon: DashboardIcon,
    route: Locations["/dashboard"],
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: ExecutionIcon,
    route: Locations["/place-order"],
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order-router"],
    showTo: [FlagEnum.ccy_conversion_client],
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order-rio-broker"],
    showTo: [FlagEnum.ccy_conversion_guest_broker_rio],
  },
  {
    icon: ExecutionIcon,
    route: Locations["/ccy-conversion/place-order-router-broker"],
    showTo: [FlagEnum.ccy_conversion_broker],
  },
  {
    route: Locations["/ccy-conversion/order-router/:id"],
    showTo: [FlagEnum.ccy_conversion_client, FlagEnum.ccy_conversion_broker],
    hideOnNavBar: true,
    hideOnMobile: true,
  },
  {
    icon: OrdersIcon,
    route: Locations["/orders"],
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: OrdersIcon,
    route: Locations["/ccy-conversion/orders"],
    showTo: [FlagEnum.ccy_conversion_client, FlagEnum.ccy_conversion_broker],
  },
  {
    icon: (props: any) => <AccountBalance {...props} />,
    route: Locations["/ccy-conversion/destination-bank-accounts-router"],
    showTo: [FlagEnum.ccy_conversion_client, FlagEnum.ccy_conversion_broker],
    hideOnMobile: true,
  },
  {
    icon: ExchangesIcon,
    route: Locations["/exchanges"],
    showTo: [FlagEnum.institutional, FlagEnum.ccy_conversion_crypto_enabled],
  },
  {
    icon: (props: any) => <Article {...props} />,
    route: Locations["/documentation"],
    hideOnMobile: true,
    showTo: [FlagEnum.institutional],
    requiresPorfolio: true,
  },
  {
    icon: AdminDashboardIcon,
    route: Locations["/admin"],
    role: Roles.admin,
    showTo: [FlagEnum.institutional],
    hideOnMobile: true,
  },
  {
    icon: (props: any) => <HowToReg {...props} />,
    route: Locations["/send-onboarding"],
    showTo: [FlagEnum.send_onboarding],
    hideOnMobile: true,
  },
] as Array<ISidebarMenuOptions>;
