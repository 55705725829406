export enum InTermsOf {
  base_ccy,
  quote_ccy,
}

export enum InTermsOfRio {
  ccy_source,
  ccy_dest,
}

export enum FlagEnum {
  "send_onboarding" = "send_onboarding",
  "institutional" = "institutional",
  "ccy_conversion_crypto_enabled" = "ccy_conversion_crypto_enabled",
  "ccy_conversion_guest_broker_rio" = "ccy_conversion_guest_broker_rio",
  "ccy_conversion_client" = "ccy_conversion_client",
  "ccy_conversion_broker" = "ccy_conversion_broker",
}

export enum Locations {
  "/onboarding" = "/onboarding",
  "/onboardingv2" = "/onboardingv2",
  "/documentation" = "/documentation",
  "/access-denied" = "/access-denied",
  "/ccy-conversion/destination-bank-accounts-router" = "/ccy-conversion/destination-bank-accounts-router",
  "/ccy-conversion/mm" = "/ccy-conversion/mm",
  "/ccy-conversion/destination-bank-accounts" = "/ccy-conversion/destination-bank-accounts",
  "/ccy-conversion/destination-bank-accounts-rio" = "/ccy-conversion/destination-bank-accounts-rio",
  "/ccy-conversion/place-order" = "/ccy-conversion/place-order",
  "/ccy-conversion/place-order-router" = "/ccy-conversion/place-order-router",
  "/ccy-conversion/place-order-rio" = "/ccy-conversion/place-order-rio",
  "/ccy-conversion/place-order-rio-broker" = "/ccy-conversion/place-order-rio-broker",
  "/ccy-conversion/place-order-router-broker" = "/ccy-conversion/place-order-router-broker",
  "/ccy-conversion/place-order-caliza" = "/ccy-conversion/place-order-caliza",
  "/ccy-conversion/orders" = "/ccy-conversion/orders",
  "/ccy-conversion/order/:id" = "/ccy-conversion/order/:id",
  "/ccy-conversion/order-rio/:id" = "/ccy-conversion/order-rio/:id",
  "/ccy-conversion/order-router/:id" = "/ccy-conversion/order-router/:id",
  "/ccy-conversion/order-caliza/:id" = "/ccy-conversion/order-caliza/:id",
  "/ccy-conversion/settings" = "/ccy-conversion/settings",
  "/place-order" = "/place-order",
  "/dashboard" = "/dashboard",
  "/orders" = "/orders",
  "/exchanges" = "/exchanges",
  "/admin" = "/admin",
  "/login" = "/login",
  "/sign-up" = "/sign-up",
  "/verify-email" = "/verify-email",
  "/reset-password" = "/reset-password",
  "/send-onboarding" = "/send-onboarding",
  "/" = "/",
}

export const Titles = {
  [Locations["/admin"]]: "Admin Dashboard",
  [Locations["/"]]: "Dashboard",
  [Locations["/onboarding"]]: "",
  [Locations["/onboardingv2"]]: "",
  [Locations["/exchanges"]]: "Exchanges",
  [Locations["/access-denied"]]: "Access denied",
  [Locations["/orders"]]: "Orders",
  [Locations["/place-order"]]: "Place Order",
  [Locations["/login"]]: "",
  [Locations["/verify-email"]]: "",
  [Locations["/sign-up"]]: "",
  [Locations["/ccy-conversion/order-rio/:id"]]: "",
  [Locations["/ccy-conversion/order-caliza/:id"]]: "",
  [Locations["/reset-password"]]: "Reset Password",
  [Locations["/documentation"]]: "Documentation",
  [Locations["/dashboard"]]: "Dashboard",
  [Locations["/ccy-conversion/mm"]]: "Order's Board",
  [Locations["/ccy-conversion/place-order"]]: "Place Order",
  [Locations["/ccy-conversion/place-order-rio"]]: "Place Order",
  [Locations["/ccy-conversion/place-order-rio-broker"]]: "Place Order",
  [Locations["/ccy-conversion/place-order-caliza"]]: "Place Order",
  [Locations["/ccy-conversion/order/:id"]]: "Place Order",
  [Locations["/ccy-conversion/orders"]]: "Orders",
  [Locations["/ccy-conversion/settings"]]: "Settings",
  [Locations["/ccy-conversion/order-router/:id"]]: "Order",
  [Locations["/ccy-conversion/destination-bank-accounts-rio"]]:
    "Destination Accounts",
  [Locations["/ccy-conversion/destination-bank-accounts"]]:
    "Destination Accounts",
  [Locations["/send-onboarding"]]: "Onboardings",
  [Locations["/ccy-conversion/place-order-router"]]: "Place Order",
  [Locations["/ccy-conversion/destination-bank-accounts-router"]]:
    "Destination Accounts",
  [Locations["/ccy-conversion/place-order-router-broker"]]: "Place Order",
};

export enum OrderTypeOptions {
  "active_trigger" = "active_trigger",
  "passive_single" = "passive_single",
  "iceberg" = "iceberg",
}

export enum Roles {
  "admin" = "admin",
}

export enum OrderStatus {
  "CANCELLED" = "CANCELLED",
  "DONE" = "DONE",
  "STOPPED_FATAL_ERROR" = "STOPPED_FATAL_ERROR",
  "STOPPED_INSUFFICIENT_FUNDS" = "STOPPED_INSUFFICIENT_FUNDS",
  "CREATED" = "CREATED",
  "RUNNING" = "RUNNING",
}

export enum ExchangeOptions {
  "Binance" = "cf455040-22de-4972-afc5-98d5c1a645e5",
  "Bitso" = "37585486-4915-46c4-97ea-1e9722f681ae",
  "OKX" = "OKX",
  "Gemini" = "Gemini",
  "CryptoDotCom" = "Crypto.com",
  "Kraken" = "Kraken",
  "Huobi" = "Huobi",
}

export enum FillStateOptions {
  "NONE" = "NONE",
  "PARTIAL" = "PARTIAL",
  "FULL" = "FULL",
}

export enum OrderClassName {
  ActiveTriggerOrder = "ActiveTriggerOrder",
  PassiveSingleOrder = "PassiveSingleOrder",
  IcebergOrder = "IcebergOrder",
}

export const exchangeOptionsAsId = {
  [ExchangeOptions["Binance"]]: "Binance",
  [ExchangeOptions["Bitso"]]: "Bitso",
  [ExchangeOptions["OKX"]]: "OKX",
  [ExchangeOptions["Gemini"]]: "Gemini",
  [ExchangeOptions["CryptoDotCom"]]: "CryptoDotCom",
  [ExchangeOptions["Kraken"]]: "Kraken",
  [ExchangeOptions["Huobi"]]: "Huobi",
};

export enum OrderTabOptions {
  orders,
  trades,
}

export enum OrderOptions {
  active,
  past,
}

export enum DestinationBankAccountTabOptions {
  MexicanBankAccount,
  UsBankAccount,
  CryptoWallet,
}

export enum DestinationBankAccountTabOptionsRio {
  "Mexico" = "Mexico",
}

export enum Sides {
  "BUY" = "BUY",
  "SELL" = "SELL",
}

export enum Modals {
  "NoModal" = "NoModal",
  "ConnectExchangeModal" = "ConnectExchangeModal",
  "DisconnectExchangeModal" = "DisconnectExchangeModal",
  "EditOrderModal" = "EditOrderModal",
  "AddBankAccountModalRouter" = "AddBankAccountModalRouter",
  "ViewBankAccountModal" = "ViewBankAccountModal",
  "ConfirmQuoteBrokerRouterModal" = "ConfirmQuoteBrokerRouterModal",
  "SendKyb" = "SendKyb",
}

export enum TimeFrameOptions {
  "1D" = "1D",
  "7D" = "7D",
  "1M" = "1M",
  "All" = "All",
}

export enum CcyConversionOrderStatus {
  "QUOTE_REQUESTED" = "QUOTE_REQUESTED",
  "QUOTE_GENERATED" = "QUOTE_GENERATED",
  "QUOTE_EXPIRED" = "QUOTE_EXPIRED",
  "QUOTE_ACCEPTED" = "QUOTE_ACCEPTED",
  "SRC_CCY_DEPOSIT_RECIEVED" = "SRC_CCY_DEPOSIT_RECIEVED",
  "DEST_CCY_TRANSFER_PENDING" = "DEST_CCY_TRANSFER_PENDING",
  "CANCELLED_BY_MM" = "CANCELLED_BY_MM",
  "CANCELLED_BY_CLIENT" = "CANCELLED_BY_CLIENT",
  "COMPLETE" = "COMPLETE",
  "NONE" = "NONE",
  "SRC_CCY_DEPOSIT_PENDING" = "SRC_CCY_DEPOSIT_PENDING",
  "CCY_CONVERSION_PENDING" = "CCY_CONVERSION_PENDING",
  "INTERMEDIATE_TX_SENT" = "INTERMEDIATE_TX_SENT",
  "STOPPED_FATAL_ERROR" = "STOPPED_FATAL_ERROR",
  "SRC_CCY_RECEIVED" = "SRC_CCY_RECEIVED",
  "INTERMEDIATE_TX_PENDING" = "INTERMEDIATE_TX_PENDING",
}

export const CcyConversionOrderStatusTranslation = {
  [CcyConversionOrderStatus["INTERMEDIATE_TX_PENDING"]]: "",
  [CcyConversionOrderStatus["QUOTE_REQUESTED"]]: "Quote Requested",
  [CcyConversionOrderStatus["QUOTE_GENERATED"]]: "Quote Generated",
  [CcyConversionOrderStatus["QUOTE_EXPIRED"]]: "Quote Expired",
  [CcyConversionOrderStatus["QUOTE_ACCEPTED"]]: "Quote Accepted",
  [CcyConversionOrderStatus["SRC_CCY_DEPOSIT_RECIEVED"]]: "CCY Conversion",
  [CcyConversionOrderStatus["DEST_CCY_TRANSFER_PENDING"]]:
    "Destination Bank Payout",
  [CcyConversionOrderStatus["CANCELLED_BY_CLIENT"]]: "Cancelled by Client",
  [CcyConversionOrderStatus["COMPLETE"]]: "Completed",
  [CcyConversionOrderStatus["CANCELLED_BY_MM"]]: "Cancelled by MM",
  [CcyConversionOrderStatus["NONE"]]: "NONE",
  [CcyConversionOrderStatus["SRC_CCY_DEPOSIT_PENDING"]]: "Awaiting Deposit",
  [CcyConversionOrderStatus["CCY_CONVERSION_PENDING"]]: "CCY Conversion",
  [CcyConversionOrderStatus["STOPPED_FATAL_ERROR"]]: "Fatal error",
  [CcyConversionOrderStatus["INTERMEDIATE_TX_SENT"]]: "Await",
  [CcyConversionOrderStatus["SRC_CCY_RECEIVED"]]: "Source CCY Received",
};

export const OrderStatusStepOptions = {
  [CcyConversionOrderStatus["QUOTE_REQUESTED"]]: 2,
  [CcyConversionOrderStatus["NONE"]]: 1,
  [CcyConversionOrderStatus["QUOTE_GENERATED"]]: 3,
  [CcyConversionOrderStatus["QUOTE_ACCEPTED"]]: 4,
  [CcyConversionOrderStatus["SRC_CCY_DEPOSIT_RECIEVED"]]: 4,
  [CcyConversionOrderStatus["QUOTE_EXPIRED"]]: 3,
  [CcyConversionOrderStatus["DEST_CCY_TRANSFER_PENDING"]]: 4,
  [CcyConversionOrderStatus["CANCELLED_BY_CLIENT"]]: 0,
  [CcyConversionOrderStatus["COMPLETE"]]: 4,
  [CcyConversionOrderStatus["CANCELLED_BY_MM"]]: 0,
  [CcyConversionOrderStatus["SRC_CCY_DEPOSIT_PENDING"]]: 3,
  [CcyConversionOrderStatus["CCY_CONVERSION_PENDING"]]: 4,
  [CcyConversionOrderStatus["STOPPED_FATAL_ERROR"]]: 0,
  [CcyConversionOrderStatus["INTERMEDIATE_TX_SENT"]]: 0,
  [CcyConversionOrderStatus["SRC_CCY_RECEIVED"]]: 0,
  [CcyConversionOrderStatus["INTERMEDIATE_TX_PENDING"]]: 0,
};

export const PlaceOrderPageTitleOptions = {
  [CcyConversionOrderStatus["NONE"]]: "Place Order",
  [CcyConversionOrderStatus["QUOTE_GENERATED"]]: "Confirm Order",
  [CcyConversionOrderStatus["QUOTE_REQUESTED"]]: "Quote Requested",
  [CcyConversionOrderStatus["QUOTE_ACCEPTED"]]: "Processing Order",
  [CcyConversionOrderStatus["SRC_CCY_DEPOSIT_RECIEVED"]]: "Processing Order",
  [CcyConversionOrderStatus["QUOTE_EXPIRED"]]: "Confirm Order",
  [CcyConversionOrderStatus["DEST_CCY_TRANSFER_PENDING"]]: "Processing Order",
  [CcyConversionOrderStatus["CANCELLED_BY_CLIENT"]]: "Place Order",
  [CcyConversionOrderStatus["COMPLETE"]]: "Order Completed",
  [CcyConversionOrderStatus["CANCELLED_BY_MM"]]: "Place Order",
  [CcyConversionOrderStatus["SRC_CCY_DEPOSIT_PENDING"]]: "Place Order",
  [CcyConversionOrderStatus["CCY_CONVERSION_PENDING"]]: "Processing Order",
  [CcyConversionOrderStatus["STOPPED_FATAL_ERROR"]]: "Fatal error",
  [CcyConversionOrderStatus["INTERMEDIATE_TX_SENT"]]: "Place Order",
  [CcyConversionOrderStatus["SRC_CCY_RECEIVED"]]: "Source CCY Received",
  [CcyConversionOrderStatus["INTERMEDIATE_TX_PENDING"]]: "",
};

export enum BankAccountNumberByTransactionType {
  "NONE" = "Destination Bank Account",
  "SPID" = "CLABE",
  "UsDomesticWire" = "Account Number",
  "CryptoWallet" = "Wallet Address",
}

export enum TransactionTypeOptions {
  "SPID" = "SPID",
  "UsDomesticWire" = "US Domestic Wire",
  "CryptoWallet" = "Crypto Wallet Transfer",
}

export enum DestinationBankAccountClassName {
  "SPID" = "SPID",
  "UsDomesticWire" = "UsDomesticWire",
  "CryptoWallet" = "CryptoWallet",
}

export enum CalizaSupportedFiat {
  "BRL" = "BRL",
}
export enum CalizaSupportedCrypto {
  "USDT" = "USDT",
  "USDC" = "USDC",
}

export enum CalizaSupportedCountry {
  "MX" = "MX",
  "PE" = "PE",
  "US" = "US",
}

export enum RioSupportedCrypto {
  "USDT" = "USDT",
}

export enum RioSupportedCountry {
  "MX" = "MX",
  "PE" = "PE",
  "US" = "US",
}

export enum RioSupportedDOCountry {
  "MX" = "MX",
  "US" = "US",
}

export enum RioTransferMethods {
  "MX_SPID" = "MX_SPID",
  "US_ACH" = "US_ACH",
  "US_WIRE" = "US_WIRE",
}

export const RioTransferMethodsTitle = {
  [RioTransferMethods["MX_SPID"]]: "SPID",
  [RioTransferMethods["US_ACH"]]: "ACH",
  [RioTransferMethods["US_WIRE"]]: "WIRE",
};

export enum WebSocketEvents {
  initial_snapshot = "initial_snapshot",
  order_update = "order_update",
}

export type SpreadType = "cents" | "bps";

export enum SpreadTargetType {
  "Rate" = "Rate",
  "Spread" = "Spread",
}

export enum CalizaNetworks {
  "SOL" = "SOL",
  "MATIC" = "MATIC",
}

export enum KYBStatus {
  "IN_REVIEW" = "IN_REVIEW",
  "ACCEPTED" = "ACCEPTED",
  "DENIED" = "DENIED",
  "IN_PROGRESS" = "IN_PROGRESS",
}

export enum IDTypes {
  "INE",
  "passport",
}

export enum DocumentTypeEnum {
  "document_id" = "Pasaporte",
  "document_ine_anverso" = "INE por delante",
  "document_ine_reverso" = "INE por detrás",
  "constancias_situacion_fiscal" = "Constancia Situación Fiscal",
  "constancias_situacion_fiscal_sociedad" = "Constancia Situación Fiscal de la Sociedad",
  "acta_constitutiva" = "Acta Constitutiva",
  "poder_representante_legal" = "Poder Representante Legal",
  "cif" = "Constancia Situación Fiscal",
}

export enum RouterSupportedFiat {
  "MXN" = "MXN",
  // "PEN" = "PEN",
  "USD" = "USD",
}
export enum RouterSupportedCrypto {
  "USDT" = "USDT",
}

export enum RouterSupportedCountry {
  "MX" = "MX",
  "PE" = "PE",
  "US" = "US",
}

export enum RouterSupportedDOCountry {
  "US" = "US",
  "MX" = "MX",
}

export enum RouterTransferMethods {
  "US_ACH" = "US_ACH",
  "US_WIRE" = "US_WIRE",
  "MX_SPID" = "MX_SPID",
}

export const RouterTransferMethodsTitle = {
  [RouterTransferMethods["US_ACH"]]: "ACH",
  [RouterTransferMethods["US_WIRE"]]: "WIRE",
  [RouterTransferMethods["MX_SPID"]]: "SPID",
};

export enum RouterBankAccountTypes {
  "CHECKING" = "CHECKING",
  "SAVINGS" = "SAVINGS",
}
