import { NavSidebarButton } from "../NavSideBarButton";
import { useLocation } from "react-router-dom";
import { useGetUserQuery } from "api/institutional/user";
import { Locations, Roles } from "interfaces/enums";

import { ISidebarMenuOptions } from "interfaces";

export const MenuItem = ({
  option,
  isBottomBar,
  handleClose,
}: {
  isBottomBar?: boolean;
  handleClose: any;
  option: ISidebarMenuOptions;
}) => {
  const pathname = useLocation().pathname as Locations;

  const { data: user } = useGetUserQuery({});

  /** Hide admin menus */
  if (option.role && option.role === Roles.admin && !user?.is_admin) {
    return null;
  }

  /** Hide mobile menus */
  if (option.hideOnMobile && isBottomBar) {
    return null;
  }

  /** Hide based on flags menus */
  if (!user?.flags.some((flag) => option.showTo.includes(flag))) {
    return null;
  }

  /** Hide nav bar menus */
  if (option.hideOnNavBar) {
    return null;
  }

  return (
    <NavSidebarButton
      selected={pathname === option.route}
      isBottomBar={isBottomBar}
      icon={option.icon}
      to={option.route}
      handleClose={handleClose}
    />
  );
};
