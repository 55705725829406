import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { closeModal, ModalState } from "app/slices/modalSlice";

import { ConnectExchangeModal } from "./ConnectExchangeModal";
import { DisconnectExchangeModal } from "./DisconnectExchangeModal";
import { EditOrderModal } from "./EditOrderModal";
import { Modals } from "interfaces/enums";

import ViewBankAccountModal from "./ViewBankAccountModal";

import AddBankAccountModalRouter from "./AddBankAccountModalRouter";
import SendKyb from "./SendKyb";
import ConfirmQuoteBrokerRouterModal from "./ConfirmQuoteBrokerRouterModal";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: white !important;
    color: black !important;
    border-radius: 8px;
    border: none;
    @media (max-width: 640px) { 
      bottom: 0;
      position: absolute;
      width: 100%;
      margin: 0;
      border-radius: 8px 8px 0 0;
    }
  }
}`;

export const ModalsPage = () => {
  const { modalName, preventBackdrop, data } = useSelector<any, ModalState>(
    (state) => state?.modal,
  );
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeModal());

  return (
    <StyledDialog
      onClose={preventBackdrop ? () => {} : handleClose}
      open={modalName !== Modals.NoModal}
    >
      {
        {
          [Modals.NoModal]: <></>,
          [Modals.ConnectExchangeModal]: (
            <ConnectExchangeModal data={data as any} />
          ),
          [Modals.DisconnectExchangeModal]: (
            <DisconnectExchangeModal data={data as any} />
          ),
          [Modals.EditOrderModal]: <EditOrderModal data={data as any} />,
          [Modals.AddBankAccountModalRouter]: (
            <AddBankAccountModalRouter data={data as any} />
          ),
          [Modals.ViewBankAccountModal]: (
            <ViewBankAccountModal data={data as any} />
          ),
          [Modals.ConfirmQuoteBrokerRouterModal]: (
            <ConfirmQuoteBrokerRouterModal data={data as any} />
          ),
          [Modals.SendKyb]: <SendKyb data={data as any} />,
        }[modalName]
      }
    </StyledDialog>
  );
};

export default ModalsPage;
