import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { AuthProvider } from "./contexts/AuthContext";

import RidianRoutes from "./routes/routes";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./app/store";

import {
  SnackbarProvider,
  MaterialDesignContent,
  OptionsObject,
} from "notistack";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import "react-date-range/dist/styles.css";
import "react-phone-number-input/style.css";
import "./styles/calendar.css";
import "./index.css";
import "./i18n";
import "./sentry";
import { useMediaQuery } from "@uidotdev/usehooks";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
});
const WrappedApp = () => {
  const notistackRef = React.useRef(null);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: "#18B45B",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: "rgb(239 68 68)",
    },
  }));

  const props: OptionsObject = {
    autoHideDuration: 2500,
  };

  const isXS = useMediaQuery("(max-width:768px)");

  if (isXS) {
    props.anchorOrigin = {
      vertical: "top",
      horizontal: "center",
    };
  }
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        {...props}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              <RidianRoutes />
            </AuthProvider>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

root.render(<WrappedApp />);

reportWebVitals();
