import { Outlet } from "react-router";

import useRouterOrderWS from "hooks/useRouterOrderWs";

export const CcyConversionProvider = () => {
  useRouterOrderWS();
  return (
    <>
      <Outlet></Outlet>
    </>
  );
};
