import { useGetUserQuery } from "api/institutional/user";
import { MenuOptions } from "./MenuOptions";
import { ButtonLoader } from "./loaders/button.loader";
import { MenuItem } from "./MenuItem";

export const NavSidebarIconMenu = ({
  isBottomBar,
  handleClose,
}: {
  isBottomBar?: boolean;
  handleClose: any;
}) => {
  const { data: user } = useGetUserQuery({});

  if (!user) return <ButtonLoader />;

  return (
    <div
      className={
        isBottomBar
          ? "flex flex-row justify-around w-full"
          : "flex gap-6 flex-col pt-6"
      }
    >
      {MenuOptions.map((option, key: number) => (
        <MenuItem
          handleClose={handleClose}
          option={option}
          isBottomBar={isBottomBar}
          key={key}
        />
      ))}
    </div>
  );
};
