import { GlobalState } from "app/slices/globalSlice";
import { CorporaShareholder, FileStructure } from "interfaces";
import { IDTypes } from "interfaces/enums";

import { useSelector } from "react-redux";

export const useMergeFiles = () => {
  const { onboarding } = useSelector<any, GlobalState>((state) => state.global);

  const getFiles = (
    corporate_shareholders?: Array<CorporaShareholder>,
  ): Array<FileStructure> => {
    const files: Array<FileStructure> = [];

    if (onboarding.id_type === IDTypes.INE) {
      onboarding.document_ine_anverso?.forEach((f) => files.push(f));
      onboarding.document_ine_reverso?.forEach((f) => files.push(f));
    } else {
      onboarding.document_id?.forEach((f) => files.push(f));
    }

    onboarding.poder_representante_legal?.forEach((f) => files.push(f));
    onboarding.constancias_situacion_fiscal?.forEach((f) => files.push(f));
    onboarding.acta_constitutiva?.forEach((f) => files.push(f));
    onboarding.constancias_situacion_fiscal_sociedad?.forEach((f) =>
      files.push(f),
    );

    corporate_shareholders?.forEach((c, index) => {
      if (c.cif?.length) c.cif.forEach((f) => files.push(f));

      if (c.id_type === IDTypes.passport) {
        if (c.document_id?.length)
          c.document_id.forEach((f) =>
            files.push({
              filename: f.filename,
              category: `corporate_shareholders.${index}.${f.category}`,
            }),
          );
      } else {
        if (c.document_ine_anverso?.length)
          c.document_ine_anverso.forEach((f) =>
            files.push({
              filename: f.filename,
              category: `corporate_shareholders.${index}.${f.category}`,
            }),
          );

        if (c.document_ine_reverso?.length)
          c.document_ine_reverso.forEach((f) =>
            files.push({
              filename: f.filename,
              category: `corporate_shareholders.${index}.${f.category}`,
            }),
          );
      }
    });
    return files.map((f) => ({ category: f.filename, filename: f.category }));
  };

  return {
    getFiles,
  };
};
