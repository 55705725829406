import { ReactComponent as RLogo } from "assets/svg/R.svg";
import { ExitToApp, Person } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import { useAuth } from "hooks/useAuth";
import { useGetUserQuery } from "api/institutional/user";

import { MenuOptions } from "components/NavSidebarIconMenu/MenuOptions";
import { MobileMenuItem } from "./MobileMenuItem";

export const MobileMenuPage = ({ handleClose }: { handleClose: any }) => {
  const { logout } = useAuth();

  const { data: user } = useGetUserQuery({});

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <div className="h-[100%] bg-white w-[100%] fixed top-0 start-0 z-10 p-5">
      <div className="flex flex-col justify-around h-full">
        <div className="flex flex-col h-full">
          <div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex">
                <RLogo />
              </div>
              <div onClick={handleClose} className="p-2 cursor-pointer">
                <Close />
              </div>
            </div>
          </div>
          <div className="pt-10 flex flex-col gap-8 items-start flex-grow">
            {MenuOptions.map((option, key: number) => (
              <MobileMenuItem
                handleClose={handleClose}
                option={option}
                key={key}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-3 align-middle justify-center items-center">
            <Person fontSize="small" />
            <span className="text-3">{user?.email}</span>
          </div>
          <div>
            <button
              onClick={handleLogout}
              className="hover:cursor-pointer flex gap-3 align-middle w-full "
            >
              <ExitToApp />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
