import { institutionalApi } from "./index";
import { RootState } from "app/store";
import { IUser } from "interfaces";
import { FlagEnum } from "interfaces/enums";

export const userApi = institutionalApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<IUser, any>({
      //@ts-ignore
      // Transform user
      queryFn: async (_arg, { getState }, _extraOptions, fetchWithBQ) => {
        const { auth } = getState() as RootState;
        if (auth?.token) {
          const result = await fetchWithBQ("/user");
          const user = result.data as IUser;
          //  user.flags = [
          //   FlagEnum.ccy_conversion_client
          //  ];
          return {
            ...result,
            data: {
              ...user,
              portfolio: user?.portfolios?.[0],
              isRouterUser: user?.flags.includes(
                FlagEnum.ccy_conversion_client,
              ),
              isRouterBroker: user?.flags.includes(
                FlagEnum.ccy_conversion_broker,
              ),
            },
          };
        }
        return new Promise((resolve) => resolve);
      },
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (params) => ({
        url: "/user",
        method: "POST",
        headers: {
          authorization: params.token,
        },
        body: {
          name: params.firstName,
          last_name: params.lastName,
          phone: params.phone,
          email: params.email,
          password: params.password,
          recaptcha: params.recaptcha,
          accepted_terms: true,
          flags: params.flags,
          referral_code: params.referralCode ?? "",
        },
      }),
    }),
  }),
});

export const { useGetUserQuery, useCreateUserMutation } = userApi;
