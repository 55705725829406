import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { jwtDecode } from "jwt-decode";
import { isAfter, secondsToMilliseconds, toDate } from "date-fns";
import { hostsInstitutional, hostsWebSocket } from "../variables/global";

export const getPath = (apiId, useCoreV1Domain = false) => {
  let baseHosts;

  switch (apiId) {
    case "institutional":
      baseHosts = hostsInstitutional;
      break;
    case "websocket":
      baseHosts = hostsWebSocket;
      break;
    default:
      return;
  }

  return baseHosts[process.env.REACT_APP_ENV];
};

export const getBaseQuery = (apiId) => {
  const baseUrl = getPath(apiId);
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = getState()?.auth?.token;
      if (token) {
        headers.set("authorization", token);
      }
      headers.set("Content-Type", "application/json");

      return headers;
    },
  });
};

export const isTokenValidFn = (token) => {
  if (token) {
    const { exp: tokenExpirationDate } = jwtDecode(token);
    const formattedExpiration = toDate(
      secondsToMilliseconds(tokenExpirationDate),
    );
    const now = toDate(new Date());
    return isAfter(formattedExpiration, now);
  }
  // if there's no token assume the endpoint is not protected and don't check
  return true;
};

export const getAuthWebSocketQuery = (apiId, url, state) => {
  const token = state?.auth?.token;
  const isValidToken = isTokenValidFn(token);

  const baseUrl = getPath(apiId);

  if (!isValidToken) {
    window.location.replace("/login?tokenExpired=true");
    return; // Early return to prevent further execution after redirecting
  }

  const query = `${baseUrl}/ws${url}`;

  return query;
};

export const customFetchBase = (apiId) => async (args, api, extraOptions) => {
  const token = args.headers?.authorization;
  const isValidToken = isTokenValidFn(token);

  if (!isValidToken) {
    window.location.replace("/login?tokenExpired=true");
  }

  const baseQuery = getBaseQuery(apiId);

  const result = await baseQuery(args, api, {});

  if (result?.data) {
    return { data: result.data };
  }
  if (result.data === null) {
    return {};
  }
  if (result?.error) {
    if (result?.error.status === 401) {
      window.location.replace("/login?tokenExpired=true");
    }
    return { error: result.error };
  }
  return { error: "Something went wrong" };
};
