import { ReactComponent as RLogo } from "assets/svg/R.svg";
import { useGetOnboardingQuery } from "api/institutional/onboarding";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InstitutionTypes from "./components/InstitutionTypes";
import { Regulated } from "./components/Regulated";
import { NonRegulated } from "./components/NonRegulated";
import { useDispatch, useSelector } from "react-redux";
import { backStep, GlobalState, setKYC } from "app/slices/globalSlice";
import { useEffect } from "react";
import { KYBStatus } from "interfaces/enums";
import { LinearProgress } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import SignUpLangSelector from "components/SignUpLangSelector";

export const Main = () => {
  const { t } = useTranslation();

  const { onboarding, step } = useSelector<any, GlobalState>(
    (state) => state.global,
  );

  const dispatch = useDispatch();

  const steps = () => {
    if (onboarding.status === KYBStatus.IN_REVIEW) {
      return (
        <div>
          <span className="text-4 pb-6 text-[#767373]">
            {t(
              "The review is in progress. This process may take up to 48 hours",
            )}
            .
          </span>
        </div>
      );
    }
    if (onboarding.status === KYBStatus.ACCEPTED) {
      return (
        <div>
          <span className="text-4 pb-6 text-[#767373]">
            {t("Your Onboarding process has been accepted")}.
          </span>
        </div>
      );
    }
    if (onboarding.status === KYBStatus.DENIED) {
      return (
        <div>
          <span className="text-4 pb-6 text-[#767373]">
            {t("Sorry, your Onboarding process has been rejected")}.
          </span>
        </div>
      );
    }
    if (step === 0) return <InstitutionTypes />;
    if (onboarding.regulated) return <Regulated />;
    if (!onboarding.regulated) return <NonRegulated />;
  };

  return (
    <div className="h-fit min-h-full flex bg-[#EFF1F2] xs:p-0 sm:p-4 xl:px-40">
      <div className="absolute top-2 right-2">
        <SignUpLangSelector />
      </div>
      <div className="m-auto bg-white rounded-lg shadow-md px-8 py-10 w-[400px] flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 align-middle items-center">
            {step > 1 && (
              <ChevronLeft
                className="h-20 w-20 cursor-pointer"
                color="action"
                onClick={() => dispatch(backStep())}
              />
            )}

            <RLogo height={"20px"} />
            <h3 className="font-bold">{t("Onboarding")}</h3>
          </div>

          {onboarding.regulated === false && (
            <div className="flex flex-col items-center gap-2 ">
              <LinearProgress
                className="w-14"
                color="success"
                variant="determinate"
                value={onboarding.status === KYBStatus.IN_REVIEW ? 50 : 0}
              />
              <span className="text-[#737373] text-3">{t("Stage 1 of 2")}</span>
            </div>
          )}
        </div>
        {steps()}
      </div>
    </div>
  );
};

export const Onboarding = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const id = params.get("id")!;
  const { isLoading, data, error } = useGetOnboardingQuery(
    { id },
    { skip: !id },
  );

  const dispatch = useDispatch();

  const { onboarding } = useSelector<any, GlobalState>((state) => state.global);

  useEffect(() => {
    if (data) {
      dispatch(setKYC(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return null;
  }
  if (!onboarding.id && error) {
    return (
      <div className="h-full w-full flex justify-center align-middle items-center">
        <div className="absolute top-2 right-2">
          <SignUpLangSelector />
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <RLogo />
          <div>
            <span className="text-3">
              {t(
                "There was an error loading the Onboarding, please verify the URL or contact a member of Ridian.",
              )}
            </span>
          </div>
          <Link className="text-3 font-semibold underline" to={"/"}>
            {t("Go back")}
          </Link>
        </div>
      </div>
    );
  }
  return <Main />;
};

export default Onboarding;
