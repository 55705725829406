import { useMemo } from "react";

import { getPath } from "../api/customFetchBase";

import useWebSocket from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import {
  ICcyConversionWSInitialSnap,
  ICcyConversionWSOrderUpdate,
} from "../interfaces";
import { WebSocketEvents } from "../interfaces/enums";

import { useGetUserQuery } from "api/institutional/user";
import { ccyConversionOrdersrouter } from "api/institutional/router_api";

type MessageType = ICcyConversionWSInitialSnap | ICcyConversionWSOrderUpdate;

const isOrderUpdate = (
  message: MessageType,
): message is ICcyConversionWSOrderUpdate => {
  return message.event === WebSocketEvents.order_update;
};

export const useRouterOrderWS = () => {
  const endpoint = "/router/order_status";

  const { data: user } = useGetUserQuery({});

  const url = useMemo(
    () =>
      user?.isRouterUser || user?.isRouterBroker
        ? `${getPath("websocket")}/ws${endpoint}`
        : null,
    [user],
  );

  const auth = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const {
    sendMessage,
    readyState,
    lastJsonMessage,
    lastMessage,
    getWebSocket,
    sendJsonMessage,
  } = useWebSocket(url, {
    onClose: () => {},
    retryOnError: true,
    queryParams: {
      authorization: encodeURIComponent(auth?.token),
    },
    shouldReconnect: () => true,
    onError: (err) => console.error(err),
    onMessage: (ev) => {
      const message = JSON.parse(ev.data) as MessageType;
      console.log(message);
      if (isOrderUpdate(message)) {
        const order = message.data;

        const order_id = order.id;

        dispatch(
          ccyConversionOrdersrouter.util.invalidateTags([
            { type: "RouterOrder", id: order_id },
          ]),
        );

        dispatch(
          ccyConversionOrdersrouter.util.invalidateTags(["RouterOrders"]),
        );
      }
    },
  });

  return {
    sendMessage,
    readyState,
    lastJsonMessage,
    lastMessage,
    getWebSocket,
    sendJsonMessage,
  };
};

export default useRouterOrderWS;
